




















import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  components: {},
})
export default class WidgetTemplate extends Vue {
  @Prop({ required: false, default: null })
  cover!: string;
}
