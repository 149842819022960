



















import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  components: {},
})
export default class SmartModal extends Vue {

  @Prop({ required: false, default: 'Widget' })
  title!: string;

  @Prop({ required: false, default: false })
  header!: boolean;

  @Prop({ required: false, default: null })
  cover!: string;

}
